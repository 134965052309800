import Vue from '@/main'
import i18n from '@/plugins/i18n'
import { bugStatus, featureStatus, userStatus } from '@/data/enums'
import { removeUniquePartOfFileName } from '@/utils'
const dateService = Vue.$flex.dateService

const badgeClassesByStatus = {
  feature: {
    [featureStatus.WAITING]: 'warning',
    [featureStatus.APPROVED]: 'info',
    [featureStatus.IN_PROGRESS]: 'secondary',
    [featureStatus.TESTING]: 'danger',
    [featureStatus.TEST_APPROVED]: 'success'
  },
  bug: {
    [bugStatus.WAITING]: 'warning',
    [bugStatus.APPROVED]: 'info',
    [bugStatus.IN_PROGRESS]: 'secondary',
    [bugStatus.TESTING]: 'danger',
    [bugStatus.TEST_APPROVED]: 'success'
  }
}

function getBadgeByStatus(status, type) {
  const getLabelBySpecialStatus = (status, type) => {
    let key,
      label

    if (status === featureStatus.APPROVED || status === featureStatus.WAITING) {
      const currentStatus =
        status === featureStatus.APPROVED
          ? featureStatus.APPROVED
          : featureStatus.WAITING

      key = `${currentStatus}${type.charAt(0).toUpperCase()}${type.slice(1)}`
    } else {
      key = status
    }

    label = i18n.t(`labels.${key}`)

    return label
  }

  return `<span class="d-flex justify-content-center badge badge-${
    badgeClassesByStatus[type][status]
  }">${getLabelBySpecialStatus(status, type)}</span>`
}

export const companyColumns = [
  { field: 'order', align: 'center' },
  {
    field: 'name',
    title: i18n.t('labels.companies')
  }
]

export const projectColumns = [
  { field: 'order' },
  {
    field: 'name',
    title: i18n.t('labels.projects')
  }
]

export const deploymentColumns = [
  {
    field: 'name',
    title: 'REPOSITORY'
  },
  {
    field: 'stagingUrl',
    title: 'STAGING URL',
    formatter: stagingUrl =>
      stagingUrl
        ? `<a href="${stagingUrl}" target="_blank">${stagingUrl}</a>`
        : stagingUrl
  },
  {
    field: 'productionUrl',
    title: 'PRODUCTION URL',
    formatter: productionUrl =>
      productionUrl
        ? `<a href="${productionUrl}" target="_blank">${productionUrl}</a>`
        : productionUrl
  }
]

export const userColumns = [
  {
    field: 'fullName',
    title: `${i18n.t('labels.firstName')} - ${i18n.t('labels.lastName')}`,
    sortable: false
  },
  {
    field: 'email',
    title: i18n.t('labels.email')
  },
  {
    field: 'role',
    title: i18n.t('labels.role'),
    formatter: value => {
      if (value === 'user') return i18n.t('labels.user')
      else return i18n.t('labels.admin')
    }
  },
  {
    field: 'company.name',
    title: i18n.t('labels.company')
  },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    align: 'center',
    formatter: value => {
      if (value === userStatus.NEW) {
        return `<span class="badge badge-warning">${i18n.t(
          'labels.new'
        )}</span>`
      } else if (value === userStatus.ACTIVE) {
        return `<span class="badge badge-success">${i18n.t(
          'labels.active'
        )}</span>`
      } else if (value === userStatus.PASSIVE) {
        return `<span class="badge badge-danger">${i18n.t(
          'labels.passive'
        )}</span>`
      }
    }
  }
]

export const featureColumns = [
  { field: 'priority', title: i18n.t('labels.priority') },

  { field: 'subject', title: i18n.t('labels.subject') },
  {
    field: 'createdBy',
    title: i18n.t('labels.createdBy'),
    formatter: value => `${value.firstName} ${value.lastName}`
  },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => getBadgeByStatus(value, 'feature')
  },
  { field: 'id', title: i18n.t('labels.featureNo') },

  {
    field: 'endDate',
    title: i18n.t('labels.remainingDay'),
    align: 'center',
    formatter: value => {
      if (value !== null) {
        const currentDate = new Date()

        return dateService.getDifferenceInDays(currentDate, value)
      }
    }
  }
]

export const featureCompletedColumns = [
  { field: 'id', title: i18n.t('labels.featureNo') },
  { field: 'subject', title: i18n.t('labels.subject') },
  { field: 'createdBy.fullName', title: i18n.t('labels.createdBy') },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => {
      if (value === featureStatus.DONE) {
        return i18n.t('labels.done')
      }
    }
  },
  {
    field: 'updatedAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const featureRejectedColumns = [
  { field: 'id', title: i18n.t('labels.featureNo') },
  { field: 'subject', title: i18n.t('labels.subject') },
  { field: 'createdBy.fullName', title: i18n.t('labels.createdBy') },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => {
      if (value === featureStatus.REJECTED) {
        return i18n.t('labels.rejected')
      }
    }
  },
  {
    field: 'updatedAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const attachmentColumns = [
  {
    field: 'name',
    title: i18n.t('labels.fileName'),
    formatter: (value, row) => {
      if (row.url) {
        return `<a href="${row.url}" target="_blank">${removeUniquePartOfFileName(value)}</a>`
      }

      return removeUniquePartOfFileName(value)
    }
  },
  {
    field: 'createdBy.fullName',
    title: i18n.t('labels.uploadedBy')
  },
  {
    field: 'createdAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const bugColumns = [
  { field: 'priority', title: i18n.t('labels.priority') },

  { field: 'subject', title: i18n.t('labels.subject') },
  { field: 'createdBy.fullName', title: i18n.t('labels.createdBy') },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => getBadgeByStatus(value, 'bug')
  },
  { field: 'id', title: i18n.t('labels.bugNo') },
  {
    field: 'createdAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const bugResolvedColumns = [
  { field: 'id', title: i18n.t('labels.bugNo') },
  { field: 'subject', title: i18n.t('labels.subject') },
  { field: 'createdBy.fullName', title: i18n.t('labels.createdBy') },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => {
      if (value === bugStatus.DONE) {
        return i18n.t('labels.done')
      }
    }
  },
  {
    field: 'updatedAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const bugRejectedColumns = [
  { field: 'id', title: i18n.t('labels.bugNo') },
  { field: 'subject', title: i18n.t('labels.subject') },
  { field: 'createdBy.fullName', title: i18n.t('labels.createdBy') },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => {
      if (value === bugStatus.REJECTED) {
        return i18n.t('labels.rejected')
      }
    }
  },
  {
    field: 'updatedAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const featureSortingOperationsColumns = [
  {
    field: 'featureId',
    title: i18n.t('labels.featureNo')
  },
  {
    field: 'createdBy.fullName',
    title: i18n.t('labels.editedBy')
  },
  {
    field: 'createdAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const bugSortingOperationsColumns = [
  {
    field: 'bugId',
    title: i18n.t('labels.bugNo')
  },
  {
    field: 'createdBy.fullName',
    title: i18n.t('labels.editedBy')
  },
  {
    field: 'createdAt',
    title: i18n.t('labels.dateClock'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const featureReportColumns = [
  {
    field: 'project.company.name',
    title: i18n.t('labels.company')
  },
  {
    field: 'project.name',
    title: i18n.t('labels.project')
  },
  {
    field: 'id',
    title: i18n.t('labels.featureNo')
  },
  {
    field: 'subject',
    title: i18n.t('labels.subject')
  },
  {
    field: 'status',
    title: i18n.t('labels.status'),
    formatter: value => {
      if ([featureStatus.APPROVED, featureStatus.WAITING].includes(value)) {
        return i18n.t(`labels.${value}Feature`)
      }

      return i18n.t(`labels.${value}`)
    }
  },
  {
    field: 'startDate',
    title: i18n.t('labels.startDate'),
    formatter: value => dateService.getDate(value) || '-'
  },
  {
    field: 'endDate',
    title: i18n.t('labels.endDate'),
    formatter: value => dateService.getDate(value) || '-'
  },
  {
    field: 'estimatedEffort',
    title: i18n.t('labels.estimatedEffort')
  },
  {
    field: 'realEffort',
    title: i18n.t('labels.realEffort')
  },
  {
    field: 'createdBy.fullName',
    title: i18n.t('labels.createdBy')
  },
  {
    field: 'createdAt',
    title: i18n.t('labels.createdAt'),
    formatter: value => dateService.getDateTime(value) || '-'
  },
  {
    field: 'isUrgent',
    title: i18n.t('labels.urgent'),
    formatter: value => (value ? i18n.t('labels.urgent') : i18n.t('labels.no'))
  },
  {
    field: 'stagingLink',
    title: i18n.t('labels.stagingLink'),
    visible: false
  },
  {
    field: 'stagingNote',
    title: i18n.t('labels.stagingNote'),
    visible: false
  },
  {
    field: 'prodLink',
    title: i18n.t('labels.prodLink'),
    visible: false
  },
  {
    field: 'prodNote',
    title: i18n.t('labels.prodNote')
  },
  {
    field: 'rejectedReason',
    title: i18n.t('labels.rejectedReason'),
    visible: false
  }
]
